
const numberToCpf = (n: string) => n.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
const numberToCnpj = (n: string) => n.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
const cpfToNumber = (n: string) => n.replace(/[^\w\s]/gi, '');
const toMoney = (n: string) => parseFloat(n).toLocaleString('pt-BR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 3
})
const toDate = (d: string): string => {
    let firstSplit: string;
    let day: string;
    let mounth: string;
    let year: string;
    if (d && d.length > 0) {
        if (String(d).split("").includes("T")) {
            firstSplit = d.split("T")[0];
            day = firstSplit.split("-")[2];
            mounth = firstSplit.split("-")[1];
            year = firstSplit.split("-")[0];
        } else {
            firstSplit = String(d).split(" ")[0];
            day = firstSplit.split("-")[2];
            mounth = firstSplit.split("-")[1];
            year = firstSplit.split("-")[0];
        }
        return `${day}/${mounth}/${year}`
    }
    return ""
}
const toDateUS = (d: string): string => {
    const firstSplit: string = d.split(" ")[0];
    const day: string = firstSplit.split("-")[2];
    const mounth: string = firstSplit.split("-")[1];
    const year: string = firstSplit.split("-")[0];
    return `${year}-${mounth}-${day}`
}


const formatDateToYYYYMMDD = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Mês começa em 0 (janeiro é 0)
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
}


export { numberToCpf, numberToCnpj, cpfToNumber, toDate, toDateUS, toMoney,formatDateToYYYYMMDD }